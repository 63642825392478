import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, ReplaySubject, Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { ActivityType } from 'src/app/model/activity-type.model';
import { Agent } from 'src/app/model/agent.model';
import { BankHolidayPlanning } from 'src/app/model/bank-holiday-planning.model';
import { Day } from 'src/app/model/day.model';
import { EventVersion } from 'src/app/model/event-version.model';
import { Event } from 'src/app/model/event.model';
import { User } from 'src/app/model/user.model';
import { WorkSchedule } from 'src/app/model/work-schedule.model';
import { AgentService } from 'src/app/service/admin/agent.service';
import { ServicePlanningPrintFacadeService } from 'src/app/service/facade/service-planning-print-facade.service';
import { UserService } from 'src/app/service/user.service';
import { eventByDayByAgentID } from 'src/app/util/planning.util';
import { DateService } from "../../../service/date.service";
import { generateLegends } from "../../../util/legends.util";
import { PlannificationUtil } from '../../../util/plannification.util';

@Component({
  selector: 'app-planning-consult',
  templateUrl: './planning-consult.component.html',
  styleUrls: ['./planning-consult.component.scss']
})

export class PlanningConsultComponent implements OnInit {
  private readonly _user$: ReplaySubject<Agent | null> = new ReplaySubject<Agent | null>(1);
  private readonly _working$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public state$: Observable<any>;

  public readonly daysOff$: ReplaySubject<Map<string, BankHolidayPlanning>> = new ReplaySubject<Map<string, BankHolidayPlanning>>(1);

  public fetching$: Observable<boolean>;

  public showLegends: boolean = false;
  public _is_planificateur: boolean;
  public _email: string;

  constructor(
    private readonly _planningConsultFacade: ServicePlanningPrintFacadeService,
    private readonly _userService: UserService,
    private readonly _agentService: AgentService,
    private readonly _dateService: DateService,
    private readonly _plannificationUtil: PlannificationUtil
  ) { }

  ngOnInit(): void {
    this._planningConsultFacade.initializeForAgent();
    this._userService.getUser$().pipe(take(1)).subscribe((user: User) => {
      this._is_planificateur = user.is_planificateur;
      this._email = user.email;
    });

    this.fetching$ = combineLatest([
      this._planningConsultFacade.fetching$,
      this._working$,
    ]).pipe(map((data) => data[0] || data[1]));

    this._userService
      .getUser$()
      .pipe(take(1))
      .subscribe((user: User) =>
        this._agentService
          .getById$(user.id)
          .subscribe((agent: Agent | null) => {
            this._user$.next(agent);
            this._assignateState(user);
          })
      );

  }

  public handleNextWeeks(): void {
    this._planningConsultFacade.nextPlanning();
    this._assignateState();
  }

  public handlePreviousWeeks(): void {
    this._planningConsultFacade.previousPlanning();
    this._assignateState();
  }

  private _assignateState(user?: User): void {
    
    this._working$.next(true);
    this._user$.pipe(take(1)).subscribe((agent: Agent | null) => {
      this.state$ = this._planningConsultFacade
        .getStateForAgent$(user && !PlannificationUtil.canAccess(user) ? agent : null , this._is_planificateur)
        // .getStateForAgent$(user  ? agent : null) 
        .pipe(
          take(1),
          map(
            (state: {
              events: Event[][];
              eventVersions: (EventVersion | null)[];
              days: Day[];
              dates: Date[][];
              agents: Agent[];
              weekNumbers: number[];
              years: number[];
              workSchedules: WorkSchedule[];
              activityTypes: ActivityType[];
            }) => {
              forkJoin(
                state.years
                  .filter(
                    (year: number, index: number) =>
                      state.years.lastIndexOf(year) != index
                  )
                  .map((year: number) =>
                    this._planningConsultFacade
                      .getBankHolidayPlannings$(year)
                      .pipe(take(1))
                  )
              )
                .pipe(take(1))
                .subscribe((plannings: BankHolidayPlanning[][]) => {
                  this.daysOff$.next(
                    plannings
                      .reduce((acc, next) => [...acc, ...next], [])
                      .reduce((acc, next: BankHolidayPlanning) => {
                        acc.set(next.date, next);
                        return acc;
                      }, new Map<string, BankHolidayPlanning>())
                  );
                });
              return {
                ...state,
                dates: state.dates.map((ds: Date[]) =>
                  ds.map((d: Date) => this._dateService.formatDate(d))
                ),
                events: eventByDayByAgentID(
                  this._is_planificateur,
                  state.events.reduce((acc, next) => [...acc, ...next], [])
                ),
                legends: generateLegends(
                  state.workSchedules,
                  state.activityTypes
                ),
              };
            }
          ),
          finalize(() => this._working$.next(false))
        );
    });
  }
}
