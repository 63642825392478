import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Agent} from 'src/app/model/agent.model';
import {Company} from 'src/app/model/company.model';
import {Job} from 'src/app/model/job.model';
import {Service} from 'src/app/model/service.model';
import {Skill} from 'src/app/model/skill.model';
import {AgentService} from 'src/app/service/admin/agent.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-agent-creation-dialog',
  templateUrl: './agent-creation-dialog.component.html',
  styleUrls: ['./agent-creation-dialog.component.scss'],
})
export class AgentCreationDialogComponent
  extends AbstractDialogComponent<Agent>
  implements OnInit
{
  services: Service[] = [];

  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Agent>>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      agent: Agent | null;
      jobs: Job[];
      skills: Skill[];
      companies: Company[];
    },
    @Inject(AgentService) _agentService: AgentService
  ) {
    super(dialogRef, _agentService, dialogData.agent);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<Agent> {
    const agent: Partial<Agent> = {
      firstName: this.inputValues.firstName,
      lastName: this.inputValues.lastName,
      email: this.inputValues.email,
      company: this.inputValues.company,
      jobs: this.inputValues.jobs ?? [],
      skills: this.inputValues.skills ?? [],
      services: this.inputValues.services ?? [],
      is_admin: !!this.inputValues.isAdmin,
      is_planificateur: !!this.inputValues.isPlanificateur,
    };

    if (!!this.data?.id) {
      agent.id = this.data.id;
    }

    return agent;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public handleCompanyChange(companyId: any): void {
    if (!!companyId && this.inputValues['company'] != companyId) {
      this.inputValues['company'] = companyId;
      this.inputValues['services'] = [];

      const currentCompany: Company = this.dialogData.companies.filter(
        ({ id }) => id === companyId
      )[0];

      this._setServices(currentCompany);
    }
  }

  protected override _initializeData({
    firstName,
    lastName,
    is_admin,
    is_planificateur,
    email,
    company,
    jobs,
    services,
    skills,
  }: Agent): void {
    this.inputValues['firstName'] = firstName;
    this.inputValues['lastName'] = lastName;
    this.inputValues['email'] = email;
    this.inputValues['company'] = company!.id;
    this.inputValues['jobs'] = jobs!.map(({ id }) => id);
    this.inputValues['services'] = services!.map(({ id }) => id);
    this.inputValues['skills'] = skills!.map(({ id }) => id);
    this.inputValues['isAdmin'] = is_admin;
    this.inputValues['isPlanificateur'] = is_planificateur;

    this._setServices(
      this.dialogData.companies.filter(({ id }) => id === company!.id)[0]
    );
  }

  private _setServices(company: Company): void {
    this.services =
      company.services?.map(
        ({ id, name, is_constant, company, company_id, users }: Service) =>
          new Service(id, name, is_constant, company_id, company, users)
      ) || [];
  }
}
