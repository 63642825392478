import { Component, Input, OnInit } from '@angular/core';
import { ActivityType } from 'src/app/model/activity-type.model';
import { Agent } from 'src/app/model/agent.model';
import { Day } from 'src/app/model/day.model';
import { Event } from 'src/app/model/event.model';
import { WorkSchedule } from 'src/app/model/work-schedule.model';
import { PlannificationUtil } from 'src/app/util/plannification.util';

@Component({
  selector: '[app-agent-row-consult]',
  templateUrl: './agent-row-consult.component.html',
  styleUrls: ['./agent-row-consult.component.scss'],
})
export class AgentRowConsultComponent implements OnInit {
  public eventsByDayPeriod: Map<
    string,
    { morning: Event[]; day: Event[]; afternoon: Event[] }
  > = new Map();

  @Input() public agent: Agent;
  @Input() public events: Map<string, Event[]> | undefined | null;
  @Input() public workSchedules: WorkSchedule[];
  @Input() public activityTypes: ActivityType[];
  @Input() public days: Day[];
  @Input() public dates: string[][];

  constructor(private readonly _plannificator: PlannificationUtil) {}

  ngOnInit(): void {
    this.eventsByDayPeriod = [...(this.events ?? [])].reduce((acc, next) => {
      const eventDate = next[0];
      const events = this._plannificator.getEventByPeriod(next[1]);
      
      const morningEvents = events.morning;
      const dayEvents = events.day;
      const afternoonEvents = events.afternoon;

      const newMorningEvents = [...morningEvents];
      const newDayEvents = [...dayEvents];
      const newAfternoonEvents = [...afternoonEvents];

      const codesToDuplicate = ['PC', 'JC'];
      newMorningEvents.forEach(event => {
        if (codesToDuplicate.includes(event.work_schedule.code)) {
          // Duplicate to afternoon
          newAfternoonEvents.push({ ...event });
        }
      });

      newDayEvents.forEach(event => {
        if (codesToDuplicate.includes(event.work_schedule.code)) {
          newAfternoonEvents.push({ ...event });
        }
      });
      acc.set(eventDate, { morning: newMorningEvents, day: newDayEvents, afternoon: newAfternoonEvents });
      return acc;
    }, new Map());
  }
}
