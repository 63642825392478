import {ACTIVITY_TYPE_UNAVAILABILITY_CODES, WORK_SCHEDULE_SPECIAL_CODE,} from 'src/constant/string.constant';
import {Event} from '../model/event.model';
import {formatHoursToDisplay} from './date.util';


export function eventByDayByAgentID(
  events: Event[]
): Map<number, Map<string, Event[]>> {
  
  return events.reduce((map, event) => {
    const id: number = event.user.id;
    if (event.user.is_planificateur) {
      if (!map.has(id)) {
        map.set(id, new Map());
      }
      if (!map.get(id)!.has(event.date)) {
        map.get(id)!.set(event.date, []);
      }
      map.get(id)!.get(event.date)!.push(event);

    } else if (event.version != null && event.version.is_published) {
      if (!map.has(id)) {
        map.set(id, new Map());
      }
      if (!map.get(id)!.has(event.date)) {
        map.get(id)!.set(event.date, []);
      }
      map.get(id)!.get(event.date)!.push(event);
    }

    return map;
  }, new Map<number, Map<string, Event[]>>());
}


export function createTextFromEvent(event: Event): string {
  if (ACTIVITY_TYPE_UNAVAILABILITY_CODES.includes(event.activity_type.code)) {
    return event.activity_type.code;
  }

  if (event.work_schedule.code == WORK_SCHEDULE_SPECIAL_CODE) {
    const operatingSiteName = event.operating_site.getName();
    const userJob = `${event.job.code}`;
    if(userJob == "AGENT")
    {
      return `${event.operating_site.getName()}\n${formatHoursToDisplay(
        event.starting_date
      )}-${formatHoursToDisplay(event.ending_date)}`;
    }
    else
    {
      return `${operatingSiteName}\n${userJob}`;
    }
  }

  return `${event.operating_site.getName()}${
    !!event.activity_type.code ? `\n${event.activity_type.code}` : ''
  }`;

 

}
