import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {Job} from "./job.model";

export class User implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public unique_identifier: string,
    public is_constant: boolean,
    public sommeil: boolean,
    public company_id: number,
    public is_admin: boolean = false,
    public is_planificateur: boolean = false,
    public jobs : Job[] = []

  ) {}

  getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
