import {Legend} from '../common/legend/draggable-legend/draggable-legend.component';
import {ActivityType} from '../model/activity-type.model';
import {WorkSchedule} from '../model/work-schedule.model';
import {generateActivityTypes} from "./activity-types.util";
import {generateWorkSchedulesMap} from "./work-schedules.util";


export function generateLegends(workSchedules: WorkSchedule[],
                                activityTypes: ActivityType[]): Legend[][] {
  const workSchedulesMap: Map<string, Legend> = generateWorkSchedulesMap(workSchedules);

  const types: Legend[] = generateActivityTypes(activityTypes);

  return [[...workSchedulesMap.values()], types];
}
