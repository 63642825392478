import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../service/admin/email.service';
import { Email } from 'src/app/model/email.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_DEFAULT_DURATION } from 'src/constant/number.constants';
@Component({
  selector: 'app-email-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.scss']
})
export class EmailEditComponent implements OnInit {
  API_KEY="1t28m348e0zhhjchmdllxqimj7aqdeizkx4dkhlmodlhqyuk";
  email: Email = new Email(0, '', '', '', '', '', 0, '', 0, '', 0, 0, 0, 0);


  id: number;

  constructor(
    private emailService: EmailService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    const idParam = this.route.snapshot.paramMap.get('id');
    if (idParam !== null) {
    this.id = +idParam;
    } else {
    console.error('ID param is missing in the route.');
    }

    this.loadEmail(this.id);
  }

  loadEmail(id: number): void {
    this.emailService.getEmailById(id).subscribe(
      (data: Email) => {
        this.email = data;
      },
      (error) => {
        console.error('Erreur lors du chargement de l\'email', error);
      }
    );
  }

  onSave(): void {
    
    this.email.send_to_superadmin = this.email.send_to_superadmin ? 1 : 0;
    this.email.sms_response = this.email.sms_response ? 1 : 0;
    this.email.phone_verification = this.email.phone_verification ? 1 : 0;
    this.email.sms_type = this.email.sms_type ? 1 : 0;
    this.email.status = this.email.status ? 1 : 0;
    console.log(JSON.stringify(this.email))
    this.emailService.updateEmail(this.email).subscribe(
      (data) => {
        this.snackBar.open('Modèle d\'email mis à jour avec succès', 'Fermer', { duration: SNACKBAR_DEFAULT_DURATION });
        this.router.navigate(['admin/email']);
      },
      (error) => {
        console.error('Erreur lors de la mise à jour de l\'email', error);
      }
    );
  }

}
