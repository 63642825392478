import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Agent} from 'src/app/model/agent.model';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {Day} from 'src/app/model/day.model';
import {EventVersion} from 'src/app/model/event-version.model';
import {Event} from 'src/app/model/event.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {verifyAndGetNextWeekWanted, verifyAndGetNextYearWanted,} from 'src/app/util/date.util';
import {DAYS_QUANTITY_FOR_PLANIFICATOR, OPERATING_LOCATION_ID} from 'src/constant/number.constants';
import {ActivityTypeService} from '../admin/activity-type.service';
import {AgentService} from '../admin/agent.service';
import {DayService} from '../admin/day.service';
import {FetchingService} from '../admin/fetching.service';
import {WorkScheduleService} from '../admin/work-schedule.service';
import {DateService} from '../date.service';
import {BankHolidayPlanningService} from '../view/bank-holiday-planning.service';
import {PlanningVersionService} from '../view/planning-version.service';
import {PlanningService} from '../view/planning.service';

// TODO if user => get server but if admin => just event on 2 weeks and version
@Injectable({
  providedIn: 'root',
})
export class ServicePlanningPrintFacadeService {
  constructor(private readonly _planningService: PlanningService,
              private readonly _versionService: PlanningVersionService,
              private readonly _agentService: AgentService,
              private readonly _dayService: DayService,
              private readonly _dateService: DateService,
              private readonly _workScheduleService: WorkScheduleService,
              private readonly _activityTypeService: ActivityTypeService,
              private readonly _bankHolidayPlanningService: BankHolidayPlanningService,
              private readonly _fetchingService: FetchingService) {
  }

  public initializeForAgent(): void {
    this._dateService.daysWanted = DAYS_QUANTITY_FOR_PLANIFICATOR;
    this._dayService.getAllWithRelationsGlobal$(['*']);
    this._agentService.getAllWithRelationsGlobal$([
      'company',
      'jobs',
      'jobs.activityType',
      'skills',
      'services',
    ]);
    this._workScheduleService.getAllWithRelationsGlobal$(['*']);
    this._activityTypeService.getAllWithRelationsGlobal$(['*']);
  }

  public get fetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  public set week(currentWeek: number) {
    this._dateService.week = currentWeek;
  }

  public set year(currentYear: number) {
    this._dateService.year = currentYear;
  }

  public set fetching(value: boolean) {
    this._fetchingService.fetching = value;
  }

  public getStateForAgent$(agent: Agent | null = null): Observable<{
    events: Event[][];
    eventVersions: (EventVersion | null)[];
    days: Day[];
    dates: Date[][];
    agents: Agent[];
    weekNumbers: number[];
    years: number[];
    workSchedules: WorkSchedule[];
    activityTypes: ActivityType[];
  }> {
    return zip(
      this._dateService.weekSelected$,
      this._dateService.yearSelected$,
      this._dayService.globalData$,
      !!agent ? of([agent]) : this._agentService.globalData$,
      this._workScheduleService.globalData$,
      this._activityTypeService.globalData$
    )
      .pipe(
        map(
          (
            data: [
              number,
              number,
              Day[],
              Agent[],
              WorkSchedule[],
              ActivityType[]
            ]
          ) => {
            const nextWeek: number = verifyAndGetNextWeekWanted(
              data[0] + 1,
              data[1]
            );
            const weekNumbers: number[] = [data[0], nextWeek];
            const yearNumbers: number[] = [
              data[1],
              nextWeek == 1 ? data[1] + 1 : data[1],
            ];
            const dates: Date[][] = weekNumbers.map(
              (week: number, index: number) =>
                this._dateService.getWeekDatesOnGivenWeekNumber(
                  week,
                  yearNumbers[index]
                )
            );

            const newData: [
              number[],
              number[],
              Date[][],
              Day[],
              Agent[],
              WorkSchedule[],
              ActivityType[]
            ] = [
              weekNumbers,
              yearNumbers,
              dates,
              data[2],
              data[3],
              data[4],
              data[5],
            ];

            return newData;
          }
        ),
        mergeMap(
          (
            data: [
              number[],
              number[],
              Date[][],
              Day[],
              Agent[],
              WorkSchedule[],
              ActivityType[]
            ]
          ) => {
            return combineLatest([
              this._versionService.getAllOnYearAndWeek$(data[1][0], data[0][0]),
              this._versionService.getAllOnYearAndWeek$(data[1][1], data[0][1]),
            ]).pipe(
              map(([version1, version2]) => {
                const lastVersion1Number: number = version1
                  .filter(_ => _.is_published)
                  .reduce(
                    (acc, {version}: EventVersion) =>
                      version > acc ? version : acc,
                    0
                  );
                const lastVersion2Number: number = version2
                  .filter(_ => _.is_published)
                  .reduce(
                    (acc, {version}: EventVersion) =>
                      version > acc ? version : acc,
                    0
                  );

                const lastVersion1 =
                  lastVersion1Number == 0
                    ? null
                    : version1.filter(
                      ({version}: EventVersion) =>
                        version == lastVersion1Number
                    )[0];
                const lastVersion2 =
                  lastVersion2Number == 0
                    ? null
                    : version2.filter(
                      ({version}: EventVersion) =>
                        version == lastVersion2Number
                    )[0];

                const d: [
                  number[],
                  number[],
                  Date[][],
                  Day[],
                  Agent[],
                  WorkSchedule[],
                  ActivityType[],
                    EventVersion | null,
                    EventVersion | null
                ] = [...data, lastVersion1, lastVersion2];

                return d;
              }),
              mergeMap(
                (
                  data: [
                    number[],
                    number[],
                    Date[][],
                    Day[],
                    Agent[],
                    WorkSchedule[],
                    ActivityType[],
                      EventVersion | null,
                      EventVersion | null
                  ]
                ) => {
                  return combineLatest([
                    this._planningService.getOnWeek$(
                      OPERATING_LOCATION_ID,
                      data[0][0],
                      data[1][0],
                      data[7]?.id,
                      agent?.id
                    ),
                    this._planningService.getOnWeek$(
                      OPERATING_LOCATION_ID,
                      data[0][1],
                      data[1][1],
                      data[8]?.id,
                      agent?.id
                    ),
                  ]).pipe(
                    map((events: [Event[], Event[]]) => {
                      const d: [
                        number[],
                        number[],
                        Date[][],
                        Day[],
                        Agent[],
                        WorkSchedule[],
                        ActivityType[],
                          EventVersion | null,
                          EventVersion | null,
                        Event[],
                        Event[]
                      ] = [...data, events[0], events[1]];

                      return d;
                    })
                  );
                }
              )
            );
          }
        )
      )
      .pipe(
        map(
          ([
             weekNumbers,
             years,
             dates,
             days,
             agents,
             workSchedules,
             activityTypes,
             version1,
             version2,
             events1,
             events2,
           ]) => {
            return {
              events: [events1, events2],
              eventVersions: [version1, version2],
              days,
              dates,
              agents,
              weekNumbers,
              years,
              workSchedules,
              activityTypes,
            };
          }
        )
      );
  }

  public getBankHolidayPlannings$(year: number): Observable<BankHolidayPlanning[]> {
    return this._bankHolidayPlanningService.getOnYear$(year);
  }

  public nextPlanning(): void {
    const currentYear = this._dateService.yearSelected$.getValue();
    const newWeek = this._dateService.weekSelected$.getValue() + 2;
    this.week = verifyAndGetNextWeekWanted(newWeek, currentYear);
    this.year = verifyAndGetNextYearWanted(newWeek, currentYear);
    this._dateService.getWeekDatesOnGivenWeekNumberGlobal$();
  }

  public previousPlanning(): void {
    const currentYear = this._dateService.yearSelected$.getValue();
    const newWeek = this._dateService.weekSelected$.getValue() - 2;
    this.week = verifyAndGetNextWeekWanted(newWeek, currentYear);
    this.year = verifyAndGetNextYearWanted(newWeek, currentYear);
    this._dateService.getWeekDatesOnGivenWeekNumberGlobal$();
  }
}
