<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">date_range</mat-icon>
    <span>Outil de planification</span>
  </mat-card-title>

  <div class="horizontal-delimiter"></div>

  <mat-card-content *ngIf="state$ | async as state">
    <app-draggable-legend *ngIf="showLegends"
                          [legends]="state.legends"
                          [boundaryLimit]="'.mat-card'"
                          [titles]="['Horaires', 'Activités']"
                          (closeWanted$)="showLegends = false">
    </app-draggable-legend>

    <app-recap-table-etat-site-legend *ngIf="showRecapEtatSite"
                                      [sitesStatusesPlanningRecap]="state.sitesStatusesPlanningRecap"
                                      [boundaryLimit]="'.mat-card'"
                                      (closeWanted$)="showRecapEtatSite = false">
    </app-recap-table-etat-site-legend>

    <div class="planning-form-container">
      <mat-slide-toggle [(ngModel)]="showLegends">
        {{ showLegends ? "Légende affichée" : "Légende cachée" }}
      </mat-slide-toggle>

      <ng-container *ngIf="mode === '1'">
        <mat-form-field class="mat-form-field__with-no-width">
          <mat-label>Rechercher</mat-label>
          <input matInput
                 type="text"
                 [value]="search$ | async"
                 (keyup)="handleSearchChange($event)"/>
        </mat-form-field>

        <mat-form-field class="mat-form-field__with-no-width">
          <mat-label>Filtrer sur une fonction</mat-label>
          <mat-select [value]="job$ | async"
                      (selectionChange)="handleSelectionChange($event)">
            <mat-option [value]="0">(Tous)</mat-option>
            <mat-option *ngFor="let job of state.jobs" [value]="job.id">{{
                job.getName()
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="mat-form-field__with-no-width"
                      *ngIf="state.versions.length">
        <mat-label>Version</mat-label>
        <mat-select [value]="state.versionSelected"
                    (selectionChange)="handleVersionChange($event)">
          <mat-option *ngFor="let version of state.versions"
                      [value]="version.id">
            {{ version.version }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button
              *ngIf="state.versions.length"
              color="primary"
              (click)="versionateEvents()"
              class="versioning-button">
        <mat-icon>{{ (isLatestEventVersionSelected$ |async) || !state.versions.length ? 'done_all' : 'draw' }}</mat-icon>
        <span>{{ (isLatestEventVersionSelected$ |async) || !state.versions.length ? 'Créer une version' : 'Modifier la version' }}</span>
      </button>

      <button mat-raised-button
              color="primary"
              (click)="publishVersion(state.versionSelected)"
              class="versioning-button">
        <mat-icon>publish</mat-icon>
        <span>Publier La version </span>
      </button>

      <button mat-raised-button
              class="versioning-button"
              color="primary"
              (click)="printPdf()"
              matTooltipShowDelay="500"
              matTooltipClass="line-broken-tooltip"
              matTooltip="Imprimer le planning sur 15 jours">
        <mat-icon>print</mat-icon>
      </button>


      <mat-slide-toggle class="etats-sites-btn"
                        [(ngModel)]="showRecapEtatSite">
        Tableau récap des états sites réalisés (
        {{ showLegends ? "affiché" : "caché" }}
        )
      </mat-slide-toggle>
    </div>
    <table class="mat-elevation-z8 mat-table">
      <thead>
      <tr class="site-tr mat-header-row">
        <ng-container *ngIf="(state.operatingLocationSelected.getName() | operatingLocationNameDisplay) === 'Par personnel'; else selectTemplate">
          <th colspan="140" class="mat-header-cell">
            Par fonction
          </th>
        </ng-container>
        
        <ng-template #selectTemplate>
          <th colspan="140" class="mat-header-cell">
            <mat-select [(ngModel)]="mode">
              <mat-option value="0">Par fonction</mat-option>
              <mat-option value="1">
                {{ state.operatingLocationSelected.getName() | operatingLocationNameDisplay }}
              </mat-option>
            </mat-select>
          </th>
        </ng-template>
        
        <th colspan="140"
            *ngFor="let site of state.operatingSitesAvailable"
            class="mat-header-cell">
          {{ site.getName() }}
        </th>
        <th colspan="80" class="mat-header-cell">Heures</th>
      </tr>
      <tr class="day-name-tr mat-header-row">
        <th colspan="140" class="mat-header-cell">
          <app-year-datepicker [currentYear]="state.yearSelected"
                               (yearChanged)="onYearChanges($event)">
          </app-year-datepicker>
        </th>
        <ng-container *ngFor="let site of state.operatingSitesAvailable">
          <th colspan="20"
              *ngFor="let day of state.days"
              class="mat-header-cell">
            {{ day.getName() }}
          </th>
        </ng-container>
        <th colspan="20" class="mat-header-cell">HS</th>
        <th colspan="20" class="mat-header-cell">HN</th>
        <th colspan="20" class="mat-header-cell">HDJF</th>
        <th colspan="20" class="mat-header-cell">TOTAL</th>
      </tr>
      <tr class="day-number-tr mat-header-row">
        <th colspan="140" class="mat-header-cell">
          <app-week-select [currentWeek]="currentWeek"
                           [currentYear]="currentYear"
                           (weekChanged)="onWeekChange($event)">
          </app-week-select>
        </th>
        <ng-container *ngFor="let site of state.operatingSitesAvailable">
          <th colspan="20"
              *ngFor="let date of state.dates"
              class="mat-header-cell"
              [class.off-day]="daysOff.has(date)"
              [matTooltipDisabled]="!daysOff.has(date)"
              matTooltipShowDelay="500"
              matTooltipClass="line-broken-tooltip"
              [matTooltip]="daysOff.get(date)?.getName() ?? ''">
            {{ date | planningDateDisplay }}
          </th>
        </ng-container>
        <th colspan="20" class="mat-header-cell hours-td">
          {{ state.hoursPayed | hsHours | numberPrecision }}
        </th>
        <th colspan="20" class="mat-header-cell hours-td">
          {{ state.hoursPayed | hnnHours | numberPrecision }}
        </th>
        <th colspan="20" class="mat-header-cell hours-td">
          {{ state.hoursPayed | hddHours | numberPrecision }}
        </th>
        <th colspan="20" class="mat-header-cell hours-td">
          {{ state.hoursPayed | TotalHours | numberPrecision }}
        </th>
      </tr>
      <tr *ngIf="mode === '1'"
          class="mat-row"
          app-site-status-header-row
          [sites]="state.operatingSitesAvailable"
          [dates]="state.dates"
          [sitesStatusPlanningByOperatingSite]="state.sitesStatusPlanningByOperatingSite">
        <th colspan="140" class="mat-header-cell"></th>
      </tr>
      </thead>

      <ng-container *ngIf="mode === '0'">
        <ng-container *ngFor="let job of state.jobs; let i = index">
          <tbody app-tbody-job
                 [ngClass]="{ 'first-tbody': i == 0 }"
                 [operatingSites]="state.operatingSitesAvailable"
                 [allDates]="state.dates"
                 [job]="job"
                 [workingSchedules]="state.workSchedules"
                 [activityTypes]="state.activityTypes"
                 [sitesStatusPlanningByOperatingSite]="state.sitesStatusPlanningByOperatingSite"
                 [sitesStatus]="state.sitesStatus">
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="mode === '1'">
        <ng-container *ngFor="let agent of state.agentsByService.get('EPCU')">
          <tr app-agent-row-service
              class="mat-row"
              [workSchedules]="state.workSchedules"
              [sitesStatusPlanningByOperatingSite]="state.sitesStatusPlanningByOperatingSite"
              [activityTypes]="state.activityTypes"
              [agent]="agent"
              [sites]="state.operatingSitesAvailable"
              [dates]="state.dates">
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </mat-card-content>
</mat-card>
