import {Injectable} from '@angular/core';
import {WORK_SCHEDULE_POSTE_CONTINU_CODE, WORK_SCHEDULE_SPECIAL_CODE,} from 'src/constant/string.constant';
import {ActivityType} from '../model/activity-type.model';
import {DayPeriod} from '../model/day.model';
import {Event, EventActivityLine} from '../model/event.model';
import {WorkSchedule} from '../model/work-schedule.model';
import {ActivityTypeService} from '../service/admin/activity-type.service';
import {WorkScheduleService} from '../service/admin/work-schedule.service';
import {User} from "../model/user.model";

export const DELIMITATOR = '/';

@Injectable({ providedIn: 'root' })
export class PlannificationUtil {
  private _activityTypes: ActivityType[] = [];
  private _workSchedules: WorkSchedule[] = [];

  constructor(
    private _activityTypeService: ActivityTypeService,
    private _workScheduleService: WorkScheduleService
  ) {
    this._activityTypeService.globalData$.subscribe(
      (data) => (this._activityTypes = data)
    );
    this._workScheduleService.globalData$.subscribe(
      (data) => (this._workSchedules = data)
    );
  }

  public createLineForEvent({
    activity_type,
    work_schedule,
    starting_date,
    ending_date,
    id,
    day_period,
  }: Event): EventActivityLine {
    return {
      activityType: activity_type,
      workSchedule: work_schedule,
      startingDate: starting_date,
      endingDate: ending_date,
      period: day_period,
      id: id,
    };
  }

  public createLineForJC(activityType: ActivityType): EventActivityLine {
    const workSchedule: WorkSchedule = this.getWorkSchedule(
      WORK_SCHEDULE_POSTE_CONTINU_CODE,
      DayPeriod.NONE
    );

    return {
      activityType: activityType,
      workSchedule: workSchedule,
      startingDate: workSchedule.starting_date,
      endingDate: workSchedule.ending_date,
      period: DayPeriod.NONE,
    };
  }

  public createLineFromActivityTypeAndDayPeriod(
    activityType: ActivityType,
    dayPeriod: DayPeriod
  ): EventActivityLine {
    const workSchedule: WorkSchedule = this.getWorkSchedule(
      activityType.favorite_work_schedule_code,
      dayPeriod
    );

    return {
      activityType: activityType,
      period: dayPeriod,
      workSchedule: workSchedule,
      startingDate: workSchedule.starting_date,
      endingDate: workSchedule.ending_date,
    };
  }
  // TODO activityType? !
  public createLinesFromSelectedMorningAndAfternoon(
    morning: any | null,
    afternoon: any | null,
    activityType?: ActivityType
  ): EventActivityLine[] {
    const data: EventActivityLine[] = [];

    if (!!morning) {
      if (morning instanceof WorkSchedule) {
        const line: EventActivityLine = {
          activityType: activityType!,
          period: DayPeriod.MORNING,
          workSchedule: morning,
          startingDate: morning.starting_date,
          endingDate: morning.ending_date,
        };
        data.push(line);
      }

      if (morning instanceof ActivityType) {
        data.push(
          this.createLineFromActivityTypeAndDayPeriod(
            morning,
            DayPeriod.MORNING
          )
        );
      }
    }

    if (!!afternoon) {
      if (afternoon instanceof WorkSchedule) {
        const line: EventActivityLine = {
          activityType: activityType!,
          period: DayPeriod.AFTERNOON,
          workSchedule: afternoon,
          startingDate: afternoon.starting_date,
          endingDate: afternoon.ending_date,
        };
        data.push(line);
      }

      if (afternoon instanceof ActivityType) {
        data.push(
          this.createLineFromActivityTypeAndDayPeriod(
            afternoon,
            DayPeriod.AFTERNOON
          )
        );
      }
    }

    return data;
  }

  public createLinesFromTyping(search: string): EventActivityLine[] {
    const searchSplitted: string[] = search
      .split(DELIMITATOR)
      .map((value) => value.toUpperCase().trim());

    if (searchSplitted.length == 0 || searchSplitted.length > 2) {
      return [];
    }

    if (searchSplitted.length == 1) {
      const activityType: ActivityType = this.getActivityType(
        searchSplitted[0]
      );

      if (!!activityType) {
        const workSchedule: WorkSchedule = this.getWorkSchedule(
          activityType.favorite_work_schedule_code,
          DayPeriod.NONE
        );
        return [
          {
            activityType: activityType,
            workSchedule: workSchedule,
            period: DayPeriod.NONE,
            startingDate: workSchedule.starting_date,
            endingDate: workSchedule.ending_date,
          },
        ];
      }
    }

    const morningActivity: ActivityType = this.getActivityType(
      searchSplitted[0]
    );

    if (!morningActivity) {
      return [];
    }

    switch (searchSplitted[1]) {
      case WORK_SCHEDULE_POSTE_CONTINU_CODE: {
        const workSchedule: WorkSchedule = this.getWorkSchedule(
          morningActivity.favorite_work_schedule_code,
          DayPeriod.NONE
        );

        return [
          {
            activityType: morningActivity,
            workSchedule: workSchedule,
            period: DayPeriod.NONE,
            startingDate: workSchedule.starting_date,
            endingDate: workSchedule.ending_date,
          },
        ];
      }

      case '': {
        const workSchedule: WorkSchedule = this.getWorkSchedule(
          morningActivity.favorite_work_schedule_code,
          DayPeriod.MORNING
        );

        return [
          {
            activityType: morningActivity,
            workSchedule: workSchedule,
            period: DayPeriod.NONE,
            startingDate: workSchedule.starting_date,
            endingDate: workSchedule.ending_date,
          },
        ];
      }

      default: {
        const afternoonActivity: ActivityType = this.getActivityType(
          searchSplitted[1]
        );

        if (!afternoonActivity) {
          return [];
        }

        const morningSchedule: WorkSchedule = this.getWorkSchedule(
          morningActivity.favorite_work_schedule_code,
          DayPeriod.MORNING
        );
        const afternoonSchedule: WorkSchedule = this.getWorkSchedule(
          morningActivity.favorite_work_schedule_code,
          DayPeriod.AFTERNOON
        );

        return [
          {
            activityType: morningActivity,
            workSchedule: morningSchedule,
            period: DayPeriod.MORNING,
            startingDate: morningSchedule.starting_date,
            endingDate: morningSchedule.ending_date,
          },
          {
            activityType: afternoonActivity,
            workSchedule: afternoonSchedule,
            period: DayPeriod.AFTERNOON,
            startingDate: afternoonSchedule.starting_date,
            endingDate: afternoonSchedule.ending_date,
          },
        ];
      }
    }
  }

  public getEventByPeriod(events: Event[]): {
    morning: Event[];
    day: Event[];
    afternoon: Event[];
  } {
    const data: {
      morning: Event[];
      day: Event[];
      afternoon: Event[];
    } = { morning: [], day: [], afternoon: [] };

    events.forEach((event) => {
      switch (event.day_period) {
        case DayPeriod.MORNING: {
          data.morning.push(event);
          break;
        }

        case DayPeriod.AFTERNOON: {
          data.afternoon.push(event);
          break;
        }

        default: {
          data.day.push(event);
          break;
        }
      }
    });

    return data;
  }

  private getActivityType(value: string): ActivityType {
    return this._activityTypes.filter(({ code }) =>
      code.toUpperCase().trim().includes(value)
    )[0];
  }

  private getWorkSchedule(
    favoriteWorkScheduleCode: string,
    period: DayPeriod
  ): WorkSchedule {
    return (
      this._workSchedules.filter(
        ({ code, day_period }) =>
          favoriteWorkScheduleCode == code && day_period == period
      )[0] ??
      this._workSchedules.filter(
        ({ code, day_period }) =>
          code == WORK_SCHEDULE_SPECIAL_CODE && day_period == period
      )[0]
    );
  }

  /*static canAccess = (user: User): boolean => {
    const rolesArray = ['COE', 'CDS', 'RM', 'ETI'];
    const hasRole = user.jobs
      .map(job => job.code)
      .filter(jobCode => rolesArray.includes(jobCode))
      .length > 0;
    return user.is_admin || hasRole;
  }*/

  static canAccess = (user: User): boolean => {
    const rolesArray = ['COE', 'CDS', 'RM', 'ETI'];
    const hasRole = user.jobs
      .map(job => job.code)
      .some(jobCode => rolesArray.includes(jobCode));
    return user.is_admin || hasRole;
  }
  



}
