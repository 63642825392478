import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {CustomDate} from 'src/app/model/custom-date.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {SiteStatus, SiteStatusPlanning,} from 'src/app/model/site-status.model';
import {getStartOfWeekDate,} from 'src/app/util/date.util';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';
import {DateService} from "../date.service";

const SITE_STATUS_PLANNING_URL = '/api/site-status-planning';

@Injectable({
  providedIn: 'root',
})
export class SiteStatusPlanningService extends AbstractService<SiteStatusPlanning> {
  constructor(@Inject(FetchingService) _fetchingService: FetchingService,
              protected readonly _httpClient: HttpClient,
              private _dateService: DateService) {
    super(_fetchingService, _httpClient, SITE_STATUS_PLANNING_URL);
  }

  public getOnWeek$(weekNumber: number,
                    year: number,
                    weeksToAdd: number = 1,
                    eventVersionNumber: number | null = null): Observable<SiteStatusPlanning[]> {
    const startOfWeekDate: Date = getStartOfWeekDate(weekNumber, year);
    const formattedStartOfWeekDate = this._dateService.formatDate(startOfWeekDate);

    return this._httpClient
      .get<SiteStatusPlanning[]>(`${SITE_STATUS_PLANNING_URL}?date=${formattedStartOfWeekDate}&weeksToAdd=${weeksToAdd}&version=${eventVersionNumber}`)
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
        map((sites: SiteStatusPlanning[]) => {
          return sites.map(this._convertData);
        })
      );
  }

  public geRecapTable$(weekNumber: number,
                       year: number,
                       eventVersionNumber: number | null = null): Observable<any> {
    const startOfWeekDate: Date = getStartOfWeekDate(weekNumber, year);
    const formattedStartOfWeekDate = this._dateService.formatDate(startOfWeekDate);

    return this._httpClient
      .get<SiteStatusPlanning[]>(`${SITE_STATUS_PLANNING_URL}/recapByWeek?starting_date=${formattedStartOfWeekDate}&version=${eventVersionNumber}`)
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
      );
  }

  public getOnWeekGlobal$(week: number,
                          year: number): void {
    this.getOnWeek$(week, year, 3)
      .subscribe((data: SiteStatusPlanning[]) => this._globalData$.next(data));
  }

  public saveSiteStatusPlanning(customDates: CustomDate[],
                                site: OperatingSite,
                                status: SiteStatus): Promise<SiteStatusPlanning[]> {
    this.setFetching(true);

    const data = customDates.map(({dateFormated, day: {id}}) => {
      return {
        date: dateFormated,
        operating_site_id: site.id,
        site_status_id: status.id,
        day_id: id,
      };
    });

    const result = this._httpClient
      .post<SiteStatusPlanning[]>(`${SITE_STATUS_PLANNING_URL}`, {data: data})
      .pipe(
        take(1),
        map((data: SiteStatusPlanning[]) => data.map(this._convertData)),
        finalize(() => this.setFetching(false))
      );

    return new Promise<SiteStatusPlanning[]>((resolve, reject) => {
      return result.subscribe({
        next(entities: SiteStatusPlanning[]) {
          return resolve(entities);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public saveSiteStatusPlanningGlobal$(customDates: CustomDate[],
                                       site: OperatingSite,
                                       status: SiteStatus): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const events: SiteStatusPlanning[] = await this.saveSiteStatusPlanning(
          customDates,
          site,
          status
        );
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next([...d, ...events]);
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public updateSiteStatusPlanning({id, date, day, operating_site}: SiteStatusPlanning,
                                  siteStatus: SiteStatus): Promise<SiteStatusPlanning> {
    this.setFetching(true);

    const data = {
      date: date,
      operating_site_id: operating_site.id,
      site_status_id: siteStatus.id,
      day_id: day.id,
    };

    const result = this._httpClient
      .put<SiteStatusPlanning>(`${SITE_STATUS_PLANNING_URL}/${id!}`, data)
      .pipe(
        take(1),
        map(this._convertData),
        finalize(() => this.setFetching(false))
      );

    return new Promise<SiteStatusPlanning>((resolve, reject) => {
      return result.subscribe({
        next(entity: SiteStatusPlanning) {
          return resolve(entity);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public updateSiteStatusPlanningGlobal$(siteStatusPlanning: SiteStatusPlanning,
                                         siteStatus: SiteStatus): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const planned = await this.updateSiteStatusPlanning(
          siteStatusPlanning,
          siteStatus
        );
        this._globalData$.pipe(take(1)).subscribe((d) => {
          const data: SiteStatusPlanning[] = d.map((e: SiteStatusPlanning) => {
            if (e.id == planned.id) {
              return planned;
            }

            return e;
          });
          this._globalData$.next([...data]);
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  protected _convertData({
                           id,
                           day,
                           date,
                           operating_site,
                           site_status,
                           event_version_id
                         }: SiteStatusPlanning): SiteStatusPlanning {
    return new SiteStatusPlanning(id, date, day, operating_site, site_status, event_version_id);
  }
}
