import {Agent} from './agent.model';
import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {Company} from './company.model';

export class Service implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public name: string,
    public is_constant: boolean,
    public company_id?: number,
    public company?: Company,
    public users?: Agent[]
  ) {
    if (!!company) {
      this.company = new Company(
        company.id,
        company.name,
        company.is_constant,
        company.services,
        company.users
      );
    }

    if (!!users) {
      this.users = users!.map(
        ({
          id,
          firstName,
          lastName,
          email,
          unique_identifier,
          is_admin,
          is_planificateur,
          is_constant,
          sommeil,
          company,
          jobs,
          services,
          skills,
        }) =>
          new Agent(
            id,
            firstName,
            lastName,
            email,
            unique_identifier,
            is_admin,
            is_planificateur,
            is_constant,
            sommeil,
            company,
            skills,
            jobs,
            services
          )
      );
    }
  }

  getName(): string {
    return this.name;
  }
}
