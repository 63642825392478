import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {ActivityType,} from 'src/app/model/activity-type.model';
import {Indisponibility} from "../../../../model/abscence.model";

export enum Action {
  MODIFY,
  DELETE,
}

@Component({
  selector: 'app-indisponibilties-menu',
  templateUrl: './indisponibilties-menu.component.html',
  styleUrls: ['./indisponibilties-menu.component.scss'],
})
export class IndisponibiltiesMenuComponent {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  @Input() activityTypes: ActivityType[];

  @Input() contextMenuPosition: { x: string; y: string };

  @Input() indisponibilitiy: Indisponibility;

  @Output() modifyEvent$: EventEmitter<{ action: Action; indisponibilitiy: Indisponibility }> = new EventEmitter();

  @Output() addLine$: EventEmitter<{ activityType: ActivityType }> = new EventEmitter();

  constructor() {
  }


  public onModifyEvent(newActivityType: ActivityType): void {
    this.indisponibilitiy.activity_type_id = newActivityType.id;
    this.modifyEvent$.emit({action: Action.MODIFY, indisponibilitiy: this.indisponibilitiy});
  }

  public onDeleteEvent(): void {
    this.modifyEvent$.emit({action: Action.DELETE, indisponibilitiy: this.indisponibilitiy});
  }

  public handleAddLine(activityType: ActivityType): void {
    this.addLine$.emit({activityType});
  }
}
