import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from 'src/app/model/user.model';
import {USER_STORAGE_KEY} from 'src/constant/string.constant';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$: ReplaySubject<User> = new ReplaySubject();
  private _user: User | null = null;

  constructor(private readonly _localStorageService: LocalStorageService) {}

  public disconnect(): void {
    this._user = null;
    this._user$.next(undefined);
  }

  public getUser$(): Observable<User> {
    if (!this._user) {
      this.setUser();
    }

    return this._user$;
  }

  public setUser(): void {
    const data: any = this._localStorageService.get(USER_STORAGE_KEY);

    if (!!data) {
      const user: User = JSON.parse(data) as User;
      this._user = new User(
        user.id,
        user.firstName,
        user.lastName,
        user.email,
        user.unique_identifier,
        user.is_constant,
        user.sommeil,
        user.company_id,
        user.is_admin,
        user.is_planificateur,
        user.jobs

      );
      this._user$.next(this._user);
    }
  }
}
