import {Component, OnInit,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {AgentCreationDialogComponent} from 'src/app/dialog/agent-creation-dialog/agent-creation-dialog.component';
import {Agent} from 'src/app/model/agent.model';
import {AgentService} from 'src/app/service/admin/agent.service';
import {User} from 'src/app/model/user.model';
import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';
import {stringifyNamedModels} from 'src/app/common/helper';
import {Company} from 'src/app/model/company.model';
import {Job} from 'src/app/model/job.model';
import {Skill} from 'src/app/model/skill.model';
import {forkJoin} from 'rxjs';
import {JobService} from 'src/app/service/admin/job.service';
import {SkillService} from 'src/app/service/admin/skill.service';
import {CompanyService} from 'src/app/service/admin/company.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss'],
})
export class AgentComponent
  extends AbstractAdministrationComponent<Agent>
  implements OnInit
{
  private _companies: Company[] = [];
  private _jobs: Job[] = [];
  private _skills: Skill[] = [];

  headersAndValues: HeaderAndValue<Agent>[] = [
    {
      displayName: 'Nom',
      propertyName: 'lastName',
      value: (agent: Agent) => agent.lastName,
    },
    {
      displayName: 'Prénom',
      propertyName: 'firstName',
      value: (agent: Agent) => agent.firstName,
    },
    {
      displayName: 'Adresse mail',
      propertyName: 'email',
      value: (agent: Agent) => agent.email,
    },
    {
      displayName: 'Identifiant',
      propertyName: 'unique_identifier',
      value: (agent: Agent) => agent.unique_identifier,
    },
    {
      displayName: "Nom de l'entreprise",
      propertyName: 'company.name',
      value: (agent: Agent) => agent.company?.name,
    },
    {
      displayName: 'Service(s)',
      propertyName: 'services',
      value: (agent: Agent) => stringifyNamedModels(agent.services ?? []),
    },
    {
      displayName: 'Fonction(s)',
      propertyName: 'jobs',
      value: (agent: Agent) => stringifyNamedModels(agent.jobs ?? []),
    },
    {
      displayName: 'Compétence(s)',
      propertyName: 'skills',
      value: (agent: Agent) => stringifyNamedModels(agent.skills ?? []),
    },
    {
      displayName: 'Actif ?',
      propertyName: 'sommeil',
      value: (agent: Agent) => (!agent.sommeil ? 'Oui' : 'Non'),
    },
    {
      displayName: 'Admin ?',
      propertyName: 'is_admin',
      value: (agent: Agent) => (agent.is_admin ? 'Oui' : 'Non'),
    },
    {
      displayName: 'planificateur ?',
      propertyName: 'is_planificateur',
      value: (agent: Agent) => (agent.is_planificateur ? 'Oui' : 'Non'),
    },

  ];

  contextMenusAndActions: ContextMenuAndAction<Agent>[] = [
    {
      name: (agent: Agent) =>
        agent.sommeil && !agent.is_constant
          ? "Veuillez réactiver l'agent pour le modifier"
          : 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: Agent) => this.handleUpdate(item),
      canBeActivated: (item: Agent) => !item.is_constant && !item.sommeil,
    },
    {
      name: (agent: Agent) =>
        agent.sommeil ? "Réactiver l'agent" : "Désactiver l'agent",
      icon: 'delete',
      color: 'warn',
      callback: (item: Agent) => this.handleDelete(item),
      canBeActivated: (item: Agent) => !item.is_constant,
    },
  ];

  constructor(
    private readonly _jobService: JobService,
    private readonly _skillService: SkillService,
    private readonly _companyService: CompanyService,
    protected readonly _agentService: AgentService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_agentService, _dialog, _snackBar);
  }

  ngOnInit(): void {
    super.ngOnInit();

    forkJoin({
      jobs: this._jobService.findJobs$(),
      skills: this._skillService.findSkills$(),
      companies: this._companyService.findAllWithRelations$(),
    }).subscribe(({ jobs, skills, companies }) => {
      this._jobs = jobs;
      this._skills = skills;
      this._companies = companies;
    });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }

  public handleUpdate(agent: Agent | null = null): void {
    const dialogRef = this._dialog.open(AgentCreationDialogComponent, {
      data: {
        agent: agent,
        jobs: this._jobs,
        skills: this._skills,
        companies: this._companies,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { firstName, lastName }: User = result as User;
          this._snackBar.open(
            `L'utilisateur ${firstName} ${lastName} a été ${
              !!agent ? 'modifié' : 'créé'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleDelete(agent: Agent): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: agent.sommeil
          ? "Voulez-vous activer l'agent ?"
          : "Voulez-vous désactiver l'agent ?",
        description: agent.sommeil
          ? "Vous pourrez à tout moment désactiver l'agent."
          : "Cette action n'est pas définitive, vous pouvez réactiver l'agent à tout moment.",
        validButton: agent.sommeil ? 'Réactiver' : 'Désactiver',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (doDeletion: boolean) => {
        if (doDeletion) {
          if (agent.sommeil) {
            await this._agentService.wake$(agent);
          } else {
            await this._agentService.delete$(agent);
          }
          this._snackBar.open(
            `L'utilisateur ${agent.firstName} ${agent.lastName} a été ${
              agent.sommeil ? 'réactivé' : 'désactivé'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }
}
