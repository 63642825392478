<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">perm_identity</mat-icon>
  <span>{{ !!this.data ? "Mise à jour de l'agent" : "Nouvel agent" }}</span>
</h1>
<div mat-dialog-content>
  <form fxLayout="column" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutAlign="center">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="45%">
          <app-custom-input
            [initialValue]="inputValues['lastName']"
            [label]="'Nom'"
            [required]="true"
            [fetching]="fetching"
            [error]="errors['lastName']"
            (value$)="handleChange('lastName', $event)"
          >
          </app-custom-input>
        </div>
        <div fxFlex="45%">
          <app-custom-input
            [initialValue]="inputValues['firstName']"
            [label]="'Prénom'"
            [required]="true"
            [fetching]="fetching"
            [error]="errors['firstName']"
            (value$)="handleChange('firstName', $event)"
          >
          </app-custom-input>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="45%">
          <app-custom-input
            [initialValue]="inputValues['email']"
            [label]="'Adresse mail'"
            [required]="true"
            [fetching]="fetching"
            [error]="errors['email']"
            (value$)="handleChange('email', $event)"
          ></app-custom-input>
        </div>
        <div fxFlex="45%">
          <mat-slide-toggle
            color="primary"
            [(ngModel)]="inputValues['isAdmin']"
            name="isAdmin"
          >
            {{ !!inputValues["isAdmin"] ? "Administrateur" : "Utilisateur" }}
          </mat-slide-toggle>
        </div>
        <div fxFlex="45%">
          <mat-slide-toggle color="primary"
                            [(ngModel)]="inputValues['isPlanificateur']"
                            name="isPlanificateur">
            Planificateur ({{ !!inputValues["isPlanificateur"] ? "Oui" : "Non" }})
          </mat-slide-toggle>
        </div>

      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="45%">
        <app-custom-select
          [initialValue]="inputValues['jobs']"
          [label]="'Fonction(s)'"
          [required]="true"
          [fetching]="fetching"
          [error]="errors['jobs']"
          (value$)="handleChange('jobs', $event)"
          [items]="dialogData.jobs"
          [multiple]="true"
        >
        </app-custom-select>
      </div>
      <div fxFlex="45%">
        <app-custom-select
          [initialValue]="inputValues['skills']"
          [label]="'Compétence(s)'"
          [required]="false"
          [fetching]="fetching"
          [error]="errors['skills']"
          (value$)="handleChange('skills', $event)"
          [items]="dialogData.skills"
          [multiple]="true"
        >
        </app-custom-select>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
      <div [fxFlex]="services.length > 0 ? '45%' : '100%'">
        <app-custom-select
          [initialValue]="inputValues['company']"
          [label]="'Entreprise'"
          [required]="true"
          [fetching]="fetching"
          [error]="errors['company']"
          (value$)="handleCompanyChange($event)"
          [items]="dialogData.companies"
        >
        </app-custom-select>
      </div>
      <div fxFlex="45%">
        <app-custom-select
          *ngIf="services.length > 0"
          [initialValue]="inputValues['services']"
          [label]="'Service(s)'"
          [required]="true"
          [fetching]="fetching"
          [error]="errors['services']"
          (value$)="handleChange('services', $event)"
          [items]="services"
          [multiple]="true"
        >
        </app-custom-select>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar mode="indeterminate" *ngIf="fetching"></mat-progress-bar>
  <button *ngIf="!fetching" mat-raised-button color="warn" (click)="onClose()">
    Annuler
  </button>
  <button
    *ngIf="!fetching"
    mat-raised-button
    [color]="!!this.data ? 'primary' : 'accent'"
    (click)="onSave()"
  >
    {{ !!this.data ? "Modifier" : "Créer" }}
  </button>
</div>
