import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Agent} from 'src/app/model/agent.model';
import {IndisponibilityFacadeService} from "../../../../service/facade/indisponibility-facade.service";
import {Event} from "../../../../model/event.model";
import {Action, IndisponibiltiesMenuComponent} from "../indisponibilties-menu/indisponibilties-menu.component";
import {ActivityType} from "../../../../model/activity-type.model";
import {MatMenuTrigger} from "@angular/material/menu";
import {
  RessourceDeletionDialogComponent
} from "../../../../dialog/ressource-deletion-dialog/ressource-deletion-dialog.component";
import {take} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {IndisponibilityService} from "../../../../service/view/indisponibility.service";
import {Indisponibility} from "../../../../model/abscence.model";
import {FetchingService} from "../../../../service/admin/fetching.service";
import {UNAVAILABLE_DATE_COLOR} from "../../../../../constant/string.constant";
import {SNACKBAR_DEFAULT_DURATION} from "../../../../../constant/number.constants";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: '[app-indisponibilties-agent-row]',
  templateUrl: './indisponibilties-agent-row.component.html',
  styleUrls: ['./indisponibilties-agent-row.component.scss'],
})
export class IndisponibiltiesAgentRowComponent implements OnInit {
  readonly UNAVAILABLE_DATE_COLOR = UNAVAILABLE_DATE_COLOR;

  @ViewChild(IndisponibiltiesMenuComponent) indisponibiltiesMenuComponent: IndisponibiltiesMenuComponent;
  @ViewChild(MatMenuTrigger) agentActionsMenu: MatMenuTrigger;

  @Input() agent: Agent;
  @Input() activityTypes: ActivityType[];
  @Input() selectedYear: number;
  @Input() weeks: number[];

  @Input() set indisponibilitiesByAgent(data: Indisponibility[]) {
    this._indisponibilitiesByAgent = data;
    this.indisponibilitiesByWeek.clear();
    data.forEach((indisponibility: Indisponibility) =>
      this.indisponibilitiesByWeek.set(indisponibility.week, indisponibility)
    );
  }

  selectedWeeks: Set<number> = new Set();
  contextMenuPosition = {x: '0px', y: '0px'};
  rowData: { agent: Agent; weeks: Set<number>, year: number };
  indisponibilitiesByWeek: Map<number, Indisponibility> = new Map<number, Indisponibility>();
  selectedIndisponibility: Indisponibility;
  private _indisponibilitiesByAgent: Indisponibility[]

  constructor(private readonly _indisponibilityFacadeService: IndisponibilityFacadeService,
              private readonly _indisponibilityService: IndisponibilityService,
              private readonly _fetchingService: FetchingService,
              protected readonly _matDialog: MatDialog,
              private readonly _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this._indisponibilityFacadeService.selectedRowData$.subscribe(
      (rowData) => {
        this.rowData = rowData
        if (this.agent.id !== rowData.agent.id) {
          this.selectedWeeks.clear();
        }
      }
    )
  }

  private getActivityTypeById = (activityTypeId: number | undefined): ActivityType | undefined => this.activityTypes.find(_ => _.id === activityTypeId)
  getActivityTypeCode = (week: number): string | undefined =>
    !this.indisponibilitiesByWeek.has(week) ? "" : this.getActivityTypeById(this.indisponibilitiesByWeek.get(week)?.activity_type_id)?.code

  getIndisponibilitiyByWeek(week: number): Indisponibility {
   return  <Indisponibility>this._indisponibilitiesByAgent.find(_ => _.agent_id === this.agent.id && _.week === week)
  }

  onPointerOver(event: PointerEvent,
                week: number) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.buttons == 1) {
      if (this.selectedWeeks.has(week)) {
        this.selectedWeeks.delete(week);
      } else {
        this.selectedWeeks.add(week);
        this._indisponibilityFacadeService.selectedRowData = {
          agent: this.agent,
          weeks: this.selectedWeeks,
          year: this.selectedYear
        };
      }

    }
  }

  onPointerDown(event: PointerEvent,
                week: number) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.buttons == 1) {
      this.selectedWeeks.clear();
      if (!this.selectedWeeks.has(week)) {
        this._indisponibilityFacadeService.selectedRowData = {
          agent: this.agent,
          weeks: this.selectedWeeks,
          year: this.selectedYear
        };
        this.selectedWeeks.add(week);
      }
    }
    if(event.buttons === 2 ){
      this.selectedIndisponibility = this.getIndisponibilitiyByWeek(week);
    }
  }

  public onActionMenu(event: MouseEvent) {
    this._setMenuPosition(event);
    this.agentActionsMenu.openMenu();
  }

  onContextMenu(event: MouseEvent,
                item?: { event: Event }) {
    this._setMenuPosition(event);
    this.indisponibiltiesMenuComponent.contextMenu.menuData = item;
    this.indisponibiltiesMenuComponent.contextMenu.menu.focusFirstItem(
      'mouse'
    );
    this.indisponibiltiesMenuComponent.contextMenu.openMenu();
  }

  handleActionOnEvent({action, indisponibilitiy}: { action: Action; indisponibilitiy: Indisponibility }): void {
    switch (action) {
      case Action.MODIFY: {
        this.onModifyEvent(indisponibilitiy);
        break;
      }
      case Action.DELETE: {
        this.onDeleteEvent(indisponibilitiy);
        break;
      }
    }
  }

  onModifyEvent(indisponibilitiy: Indisponibility): void {
    this._indisponibilityService.updateOne$(indisponibilitiy).subscribe(_ => {
      this._fetchingService.fetching = false
      this._indisponibilityFacadeService.isIndisponibilitiesByAgentToBeRefeshed = true;
      this._snackBar.open(
        `L'indisponibilité a été modifiée avec succès !`,
        'Fermer',
        {duration: SNACKBAR_DEFAULT_DURATION}
      );
    })

  }

  onDeleteEvent(indisponibilitiy: Indisponibility): void {
    this._indisponibilityService.deleteOne$(indisponibilitiy).subscribe(_ => {
      this._fetchingService.fetching = false
      this._indisponibilityFacadeService.isIndisponibilitiesByAgentToBeRefeshed = true;
      this._snackBar.open(
        `L'indisponibilité a été supprimée avec succès !`,
        'Fermer',
        {duration: SNACKBAR_DEFAULT_DURATION}
      );
    })

  }

  handleContextAddLine({activityType}: { activityType: ActivityType }): void {
    this._fetchingService.fetching = true;
    const reqData = [...this.rowData.weeks].map(week => ({
      agent_id: this.agent.id,
      activity_type_id: activityType.id,
      week: week,
      year: this.rowData.year
    }))

    this._indisponibilityService.saveMultiple$(reqData).subscribe(_ => {
      this._fetchingService.fetching = false
      this._indisponibilityFacadeService.isIndisponibilitiesByAgentToBeRefeshed = true
    })
  }

  handleDeleteEvents(): void {
    const dialogRef = this._matDialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: `Voulez-vous supprimer l'ensemble des indisponibilités de ${this.agent.getName()} ?`,
        description:
          'Cette action va supprimer tout les évènements, Congés / JARTT',
        validButton: 'Supprimer',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((doDeletion: boolean) => {
        if (doDeletion) {
          this._fetchingService.fetching = true;
          this._indisponibilityFacadeService.deleteMultiple(this._indisponibilitiesByAgent).subscribe(() => {
              this._indisponibilityFacadeService.isIndisponibilitiesByAgentToBeRefeshed = true
              this._fetchingService.fetching = false
              this._snackBar.open(
                `Toutes les indisponibilités de l'agent ${this.agent.getName()} ont été créées avec succès !`,
                'Fermer',
                {duration: SNACKBAR_DEFAULT_DURATION}
              );
            }
          );
        }
      });
  }


  private _setMenuPosition(event: MouseEvent): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
  }

}
