import {ActivityType} from './activity-type.model';
import {Agent} from './agent.model';
import {IdentifiedModel} from './base-model.model';
import {Company} from './company.model';
import {Day, DayPeriod} from './day.model';
import {EventVersion} from './event-version.model';
import {Job} from './job.model';
import {OperatingSite} from './operating-site.model';
import {Service} from './service.model';
import {Skill} from './skill.model';
import {WorkSchedule} from './work-schedule.model';

export const compareEvents = (a: Event, b: Event) =>
  a.starting_date.localeCompare(b.starting_date);

export class Event implements IdentifiedModel {
  constructor(
    public id: number,
    public date: string,
    public starting_date: string,
    public ending_date: string,
    public hours_payed: number,
    public comment: string | null,
    public rh_errors: { [rule_name: string]: string },
    public site_status_errors: string[],
    public day_period: DayPeriod,
    public day: Day,
    public user: Agent,
    public company: Company,
    public operating_site: OperatingSite,
    public job: Job,
    public skills: Skill[],
    public services: Service[],
    public version: EventVersion,
    public work_schedule: WorkSchedule,
    public activity_type: ActivityType,
    public is_versioned: boolean,
    public event_version_id?: number,
    public event_version?: EventVersion
  ) {
    this.day = new Day(day.id, day.day, day.code, day.is_constant);
    this.user = new Agent(
      user.id,
      user.firstName,
      user.lastName,
      user.email,
      user.unique_identifier,
      user.is_admin,
      user.is_planificateur,
      user.is_constant,
      user.sommeil,
      user.company,
      user.skills,
      user.jobs,
      user.services
    );
    this.company = new Company(
      company.id,
      company.name,
      company.is_constant,
      company.services,
      company.users
    );
    this.operating_site = new OperatingSite(
      operating_site.id,
      operating_site.name,
      operating_site.is_constant,
      operating_site.operating_location_id,
      operating_site.operating_location
    );
    this.job = new Job(
      job.id,
      job.name,
      job.code,
      job.activity_type_id,
      job.column_order,
      job.is_constant,
      job.activity_type
    );
    this.skills = skills.map(
      (skill) => new Skill(skill.id, skill.name, skill.is_constant)
    );
    this.services = services.map(
      (service) =>
        new Service(
          service.id,
          service.name,
          service.is_constant,
          service.company_id,
          service.company,
          service.users
        )
    );
    this.work_schedule = new WorkSchedule(
      work_schedule.id,
      work_schedule.name,
      work_schedule.code,
      work_schedule.day_period,
      work_schedule.starting_date,
      work_schedule.ending_date,
      work_schedule.legend_color,
      work_schedule.site_status_id,
      work_schedule.is_constant
    );
    this.activity_type = new ActivityType(
      activity_type.id,
      activity_type.name,
      activity_type.code,
      activity_type.favorite_work_schedule_code,
      activity_type.is_constant
    );
  }
}

export interface EventActivityLine {
  id?: number;
  period: DayPeriod;
  activityType: ActivityType;
  workSchedule: WorkSchedule;
  startingDate: string;
  endingDate: string;
}
