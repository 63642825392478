import {Legend} from '../common/legend/draggable-legend/draggable-legend.component';
import {WorkSchedule} from '../model/work-schedule.model';


export const generateWorkSchedulesMap = (schedules: WorkSchedule[]): Map<string, Legend> =>
  schedules.reduce(
    (map, {code, legend_color}) => {
      const legend: Legend = {
        name: code,
        color: legend_color,
      };
      map.set(`${code}${legend_color}`, legend);
      return map;
    },
    new Map()
  );

