import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {DayPeriod} from 'src/app/model/day.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {PlannificationUtil} from 'src/app/util/plannification.util';
import {AbstractRowDirective} from '../../abstract-row.directive';
import {JobContextMenuComponent} from '../../job-context-menu/job-context-menu.component';

@Component({
  selector: '[app-agent-row-service]',
  templateUrl: './agent-row-service.component.html',
  styleUrls: ['./agent-row-service.component.scss'],
})
export class AgentRowServiceComponent extends AbstractRowDirective implements OnInit, OnDestroy {
  @ViewChild(JobContextMenuComponent)
   jobContextMenuComponent: JobContextMenuComponent;

   isLatestEventVersionSelected$: Observable<boolean>;

  constructor(protected readonly _dialog: MatDialog,
              protected readonly _snackBar: MatSnackBar,
              protected readonly _planningFacade: PlanningFacadeService,
              protected readonly _plannificator: PlannificationUtil) {
    super(_dialog, _snackBar, _planningFacade, _plannificator);
    this.isLatestEventVersionSelected$ = this._planningFacade.isLatestEventVersionSelected$;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

   onContextMenu(event: MouseEvent,
                       item:
                         | { site: OperatingSite; date: string; day_period: number }
                         | { event: Event }
  ) {
    this.isLatestEventVersionSelected$.pipe(take(1))
      .subscribe((isLatest: boolean) => {
      if (isLatest) {
        this._setMenuPosition(event);
        if (Object.keys(item).includes('event')) {
          this.jobContextMenuComponent.activityContextMenuComponent.contextMenu.menuData =
            item;
          this.jobContextMenuComponent.activityContextMenuComponent.contextMenu.menu.focusFirstItem(
            'mouse'
          );
          this.jobContextMenuComponent.activityContextMenuComponent.contextMenu.openMenu();
        } else if (Object.keys(item).includes('site')) {
          this.jobContextMenuComponent.contextMenu.menuData = item;
          this.jobContextMenuComponent.contextMenu.menu.focusFirstItem('mouse');
          this.jobContextMenuComponent.contextMenu.openMenu();
        }
      } else {
        event.preventDefault();
      }
    });
  }

   handleContextSingleTDClickedService({
                                               site,
                                               date,
                                               day_period,
                                               job,
                                               astreinte,
                                             }: {
    site: OperatingSite;
    date: string;
    day_period: DayPeriod;
    job: Job;
    astreinte: any;
  }): void {
    this.jobSelected = job;
    this.handleContextSingleTDClicked({site, date, day_period, astreinte});
  }

   handleContextAddLineService({
                                       morning,
                                       afternoon,
                                       site,
                                       job,
                                     }: {
    morning: any | null;
    afternoon: any | null;
    job: Job;
    site: OperatingSite;
  }): void {
    this.job = job;
    this.handleContextAddLine({morning, afternoon, site});
  }

   handleClickOnJob(job: Job): void {
    this.job = job;
    this.handleDuplicateEvents();
  }
}
