import {Directive, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Agent} from 'src/app/model/agent.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {PlannificationUtil} from 'src/app/util/plannification.util';
import {Event, EventActivityLine} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {map, take} from 'rxjs/operators';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RhErrorsDialogComponent} from 'src/app/dialog/rh-errors-dialog/rh-errors-dialog.component';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {EventCreationDialogComponent} from 'src/app/dialog/event-creation-dialog/event-creation-dialog.component';
import {DayPeriod} from 'src/app/model/day.model';
import {Action} from './activity-context-menu/activity-context-menu.component';
import {formatDateToDisplay} from 'src/app/util/date.util';
import {
  ResourcePlanificatorDialogComponent
} from 'src/app/dialog/resource-planificator-dialog/resource-planificator-dialog.component';
import {HourPayed} from 'src/app/model/hour-payed.model';
import {
  SiteStatusPlanningErrorDialog
} from "../../../dialog/site-status-planning-errors-dialog/site-status-errors-dialog.component";
import {SiteStatus} from "../../../model/site-status.model";

@Directive()
export abstract class AbstractRowDirective implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;

  job: Job | null = null;

  fetching$: Observable<boolean>;
  rhErrors: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hasRhErrors$: Observable<boolean> = this.rhErrors.pipe(
    map((value) => value.length > 0)
  );

  siteStatusPlanningErrors: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hasSiteStatusPlanningErrors$: Observable<boolean> = this.siteStatusPlanningErrors.pipe(
    map((value) => value.length > 0)
  );

  selectedOperatingSite: OperatingSite | null = null;
  readonly selected: Set<number> = new Set();
  readonly selectedCellsToBeCopied: Set<number> = new Set();




  events: {
    [date: string]: { morning: Event[]; day: Event[]; afternoon: Event[] };
  };

  hoursPayed: HourPayed[] = [];

  @Input()
  sites: OperatingSite[];

  @Input()
  dates: string[];

  @Input()
  agent: Agent;

  @Input()
  workSchedules: WorkSchedule[];

  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;

  @Input()
  sitesStatus: SiteStatus[];

  @Input()
  activityTypes: ActivityType[];


  @Input()
  set jobSelected(j: Job) {
    if (!!j) {
      this.job = j;
    }
  }

  @ViewChild(MatMenuTrigger)
  userActions: MatMenuTrigger;

  contextMenuPosition = {x: '0px', y: '0px'};


  constructor(protected readonly _dialog: MatDialog,
              protected readonly _snackBar: MatSnackBar,
              protected readonly _planningFacade: PlanningFacadeService,
              protected readonly _plannificator: PlannificationUtil) {
    this.fetching$ = this._planningFacade.fetching$;
  }

  ngOnInit(): void {
    this._subscription = this._planningFacade
      .getEventsForAgent$(this.agent)
      .subscribe((data) => {
        this.events = Object.keys(data).reduce((acc, next) => {
          const periods: {
            morning: Event[];
            day: Event[];
            afternoon: Event[];
          } = this._plannificator.getEventByPeriod(data[next]);
          acc[next] = periods;
          return acc;
        }, {} as { [date: string]: { morning: Event[]; day: Event[]; afternoon: Event[] } });
        this._setErrors();
      });
    this._subscription.add(
      this._planningFacade.agentMultiSelected$.subscribe(
        (data: { agent: Agent; job: Job | null } | null) => {
          if (!!data) {
            if (!!this.job) {
              if (
                this.agent.id != data.agent.id ||
                this.job.id != data.job!.id
              ) {
                this._resetSelection();
              }
            } else {
              if (this.agent.id != data.agent.id) {
                this._resetSelection();
              }
            }
          }
        }
      )
    );
    this._subscription.add(
      this._planningFacade
        .getHoursPayedForAgent$(this.agent.id)
        .subscribe((hours: HourPayed[]) => (this.hoursPayed = hours))
    );
    /*this._subscription.add(
      this._planningFacade.cellsToBeCopiedData$.subscribe(
        (data: { agent: Agent; job: Job | null } | null) => {
            if ((this.agent.id !== data!.agent.id || this.job!.id !== data!.job!.id)) {
              this.selectedCellsToBeCopied.clear();
          }
        }
      )
    );*/

  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  onActionMenu(event: MouseEvent) {
    this._setMenuPosition(event);
    this.userActions.openMenu();
  }

  onModifyEvent(event: Event): void {
    const line: EventActivityLine =
      this._plannificator.createLineForEvent(event);
    this._showModal([line], event.operating_site, [event.date], event.comment)
      .pipe(take(1))
      .subscribe();
  }

  onDeleteEvent(event: Partial<Event>): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: "Voulez-vous supprimer l'événement ?",
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (doDeletion: boolean) => {
        if (doDeletion) {
          await this._planningFacade.deleteEvent$(event as Event);
          this._snackBar.open(
            `La planification a été supprimée avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
        }
      });
  }

  handleContextSingleTDClicked({
                                 site,
                                 date,
                                 day_period,
                                 astreinte,
                               }: {
    site: OperatingSite;
    date: string;
    day_period: DayPeriod;
    astreinte: any;
  }): void {
    if (astreinte instanceof WorkSchedule) {
      this.handleSingleWorkScheduleSelected(site, date, day_period, astreinte);
    }

    if (astreinte instanceof ActivityType) {
      this.handleSingleTDClicked(site, date, day_period, astreinte);
    }
  }

  handleActionOnEvent({
                        action,
                        event,
                      }: {
    action: Action;
    event: Event;
  }): void {
    switch (action) {
      case Action.MODIFY: {
        this.onModifyEvent(event);
        break;
      }

      case Action.DELETE: {
        this.onDeleteEvent(event);
        break;
      }
    }
  }

  handleContextAddLine({
                         morning,
                         afternoon,
                         site,
                       }: {
    morning: any | null;
    afternoon: any | null;
    site: OperatingSite;
  }): void {
    this.handleAddLine(morning, afternoon, site);
  }

  handleSingleWorkScheduleSelected(site: OperatingSite,
                                   date: string,
                                   dayPeriod: DayPeriod,
                                   workSchedule: WorkSchedule) {
    const line: EventActivityLine = {
      activityType: this.job!.activity_type!,
      period: dayPeriod,
      workSchedule: workSchedule,
      startingDate: workSchedule.starting_date,
      endingDate: workSchedule.ending_date,
    };

    this._showModal([line], site, [date], null).subscribe();
  }

  handleSingleTDClicked(site: OperatingSite,
                        date: string,
                        dayPeriod: DayPeriod,
                        activityType: ActivityType) {
    const line: EventActivityLine =
      this._plannificator.createLineFromActivityTypeAndDayPeriod(
        activityType,
        dayPeriod
      );

    this._showModal([line], site, [date], null).subscribe();
  }

  handleAddLine(morning: any | null,
                afternoon: any | null,
                site: OperatingSite) {
    const data: EventActivityLine[] =
      this._plannificator.createLinesFromSelectedMorningAndAfternoon(
        morning,
        afternoon,
        this.job!.activity_type
      );

    const dates: string[] = this._getDateSelected();

    this._showModal(data, site, dates, null).subscribe(() =>
      this._resetSelection()
    );
  }

  onPointerOver(event: PointerEvent,
                index: number,
                site: OperatingSite) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (this.selectedOperatingSite?.id == site.id && event.buttons == 1) {
      if (this.selected.has(index)) {
        this.selected.delete(index);
        this.selected.delete(index + 1);
      } else {
        this.selected.add(index);
        this.selected.add(index + 1);
      }

      this._planningFacade.agentMultiSelected = {
        agent: this.agent,
        job: this.job,
      };
    }
  }

  onPointerDown(event: PointerEvent,
                index: number,
                site: OperatingSite) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.buttons == 1) {
      if (this.selectedOperatingSite != site && !this.selected.has(index)) {
        this._planningFacade.agentMultiSelected = {
          agent: this.agent,
          job: this.job,
        };
        this.selectedOperatingSite = site;
        this.selected.clear();
        this.selected.add(index);
        this.selected.add(index % 2 == 0 ? index + 1 : index - 1);
      } else {
        this._resetSelection();
      }
    }
  }

  selectCelToCopy(pointerEvent: PointerEvent,event: Event) 
  {
    pointerEvent.stopImmediatePropagation();
    pointerEvent.preventDefault();
    if (pointerEvent.buttons == 1) {
      if (!this.selectedCellsToBeCopied.has(event.id)) 
      {
        this._planningFacade.cellsToBeCopiedData = {
        agent: this.agent,
        job: this.job,
        };
        this.selectedCellsToBeCopied.add(event.id);
      } 
      else 
      {
        this.selectedCellsToBeCopied.delete(event.id);
      }
    }
  }


  showRhErrors(): void {
    if (this.rhErrors.getValue().length > 0) {
      this._dialog.open(RhErrorsDialogComponent, {
        data: {user: this.agent, errors: [...this.rhErrors.getValue()]},
      });
    }
  }

  showSiteStatusPlanningErrors(): void {
    if (this.siteStatusPlanningErrors.getValue().length > 0) {
      this._dialog.open(SiteStatusPlanningErrorDialog, {
        data: {errors: [...this.siteStatusPlanningErrors.getValue()]},
      });
    }
  }

  handleDeleteEvents(): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title:
          "Voulez-vous supprimer l'ensemble des évènements de la semaine ?",
        description:
          'Cette action va supprimer tout les évènements, Congés / JARTT / Astreintes compris.',
        validButton: 'Supprimer',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((doDeletion: boolean) => {
        if (doDeletion) {
          this._planningFacade.deleteMultiple(this.agent);
        }
      });
  }

  protected _setErrors(): void {
    const rhErrorsSet: Set<string> = new Set<string>();
    const siteStatusPlanningErrorsSet: Set<string> = new Set<string>();
    Object.keys(this.events).forEach((date: string) => {
      [
        ...this.events[date].morning,
        ...this.events[date].day,
        ...this.events[date].afternoon,
      ].forEach(({rh_errors, site_status_errors}) => {
          Object.keys(rh_errors).forEach((rhName: string) =>
            rhErrorsSet.add(rh_errors[rhName])
          )
          site_status_errors.forEach(site_status_error =>
            siteStatusPlanningErrorsSet.add(site_status_error)
          )
        }
      );
    });

    this.rhErrors.next([...rhErrorsSet]);
    this.siteStatusPlanningErrors.next([...siteStatusPlanningErrorsSet]);
  }


  public handleCopySelectedSchedules(): void {
    const selectedCellsArray = Array.from(this.selectedCellsToBeCopied);

    forkJoin([
      this._planningFacade.multipleDataForResourcePlanificator$,
      this._planningFacade.getAgentsForJob$(this.job!).pipe(
        take(1),
        map((agents: Agent[]) => agents.filter(({ id }) => id != this.agent.id))
      ),
      this._planningFacade.plannings$.pipe(
        take(1),
        map((events: Event[]) => events.filter(({ user: { id } }) => id != this.agent.id))
      ),
      this._planningFacade.plannings$.pipe(
        take(1),
        map((events: Event[]) => events.filter(event => this.selectedCellsToBeCopied.has(event.id)))
      )
    ])
    .pipe(take(1))
    .subscribe({
      next: ([[skills, companies, services, days], agents, allEvents, filteredEvents]) => {
        if (selectedCellsArray.length > 0) {
        const dialogRef = this._dialog.open(ResourcePlanificatorDialogComponent, {
          data: {
            job: this.job,
            agents: agents,
            events: [...allEvents, ...filteredEvents],
            dates: this.dates,
            days: days,
            skills: skills,
            companies: companies,
            services: services
          }
        });
  
        dialogRef.afterClosed().subscribe({
          next: (data: any | null) => {
            if (!!data && Array.isArray(data) && data.length > 0) {
              this._planningFacade.fetching = true;
  
              this._planningFacade
                .getEventsForAgent$(this.agent)
                .pipe(take(1))
                .subscribe((eventsByDate: { [date: string]: Event[] }) => {
                  const events: Event[] = Object.values(eventsByDate)
                    .reduce((acc, next) => [...acc, ...next], [])
                    .filter(event => this.selectedCellsToBeCopied.has(event.id) || this.selectedCellsToBeCopied.size === 0);
  
                  this._planningFacade
                    .duplicateEvents$(events, data)
                    .then((usersWithErrors: { [date: string]: Agent[] }) => {
                      const keys: string[] = Object.keys(usersWithErrors);
                      if (keys.length > 0) {
                        const message: string = keys
                          .map((key: string) =>
                            usersWithErrors[key].reduce(
                              (acc, { firstName, lastName }: Agent) => {
                                if (!acc.includes(`${firstName} ${lastName}`)) {
                                  return `${acc}\n\t- ${firstName} ${lastName}`;
                                }
                                return acc;
                              },
                              `${formatDateToDisplay(key)}:`
                            )
                          )
                          .reduce(
                            (acc, next, index: number) =>
                              index === 0 ? next : `${acc}\n${next}`,
                            ''
                          );
                        this._snackBar.open(
                          `Des évènements n'ont pu être dupliqués à cause de collision : \n${message}`,
                          'Fermer',
                          {
                            panelClass: ['snackbar-white-space'],
                          }
                        );
                      } else {
                        this._snackBar.open(
                          'Tout les évènements ont été dupliqués avec succès !',
                          'Fermer',
                          { duration: SNACKBAR_DEFAULT_DURATION }
                        );
                      }
                      this._planningFacade.fetching = false;
                    })
                    .catch((err) => console.log(err));
                });
            }
          },
          error: (err) => {
            console.error('Error handling dialog data:', err);
            this._snackBar.open(
              'Une erreur est survenue lors de la gestion des données sélectionnées.',
              'Fermer',
              { duration: SNACKBAR_DEFAULT_DURATION }
            );
          }
        });
        }
        else{
          this._snackBar.open(
            'Aucun événement trouvé pour les cellules sélectionnées.',
            'Fermer',
            { duration: SNACKBAR_DEFAULT_DURATION }
          );
        }
  
        
      },
      error: (err) => {
        console.error('Error fetching data for resource planificator:', err);
        this._snackBar.open(
          'Une erreur est survenue lors de la récupération des données.',
          'Fermer',
          { duration: SNACKBAR_DEFAULT_DURATION }
        );
      }
    });
  }
  
  
  handleDuplicateEvents(): void {
    forkJoin([
      this._planningFacade.multipleDataForResourcePlanificator$,
      this._planningFacade.getAgentsForJob$(this.job!).pipe(
        take(1),
        map((agents: Agent[]) => agents.filter(({id}) => id != this.agent.id))
      ),
      this._planningFacade.plannings$.pipe(
        take(1),
        map((events: Event[]) =>
          events.filter(({user: {id}}) => id != this.agent.id)
        )
      ),
    ])
      .pipe(take(1))
      .subscribe(([[skills, companies, services, days], agents, events]) => {
        const data = this._dialog.open(ResourcePlanificatorDialogComponent, {
          data: {
            job: this.job,
            agents: agents,
            events: events,
            dates: this.dates,
            days: days,
            skills: skills,
            companies: companies,
            services: services,
          },
        });
        data.afterClosed().subscribe((data: any | null) => {
          if (!!data && Array.isArray(data) && data.length > 0) {
            this._planningFacade.fetching = true;

            this._planningFacade
              .getEventsForAgent$(this.agent)
              .pipe(take(1))
              .subscribe((eventsByDate: { [date: string]: Event[] }) => {
                const events: Event[] = Object.values(eventsByDate)
                  .reduce((acc, next) => [...acc, ...next], [])
                  .filter(event => this.selectedCellsToBeCopied.has(event.id) || this.selectedCellsToBeCopied.size === 0)

                this._planningFacade
                  .duplicateEvents$(events, data)
                  .then((usersWithErrors: { [date: string]: Agent[] }) => {
                    const keys: string[] = Object.keys(usersWithErrors);
                    if (keys.length > 0) {
                      const message: string = keys
                        .map((key: string) =>
                          usersWithErrors[key].reduce(
                            (acc, {firstName, lastName}: Agent) => {
                              if (!acc.includes(`${firstName} ${lastName}`)) {
                                return `${acc}\n\t- ${firstName} ${lastName}`;
                              }
                              return acc;
                            },
                            `${formatDateToDisplay(key)}:`
                          )
                        )
                        .reduce(
                          (acc, next, index: number) =>
                            index == 0 ? next : `${acc}\n${next}`,
                          ''
                        );
                      this._snackBar.open(
                        `Des évènements n\'ont pu être dupliqués à cause de collision : \n${message}`,
                        'Fermer',
                        {
                          panelClass: ['snackbar-white-space'],
                        }
                      );
                    } else {
                      this._snackBar.open(
                        'Tout les évènements ont été dupliqués avec succès !',
                        'Fermer',
                        {duration: SNACKBAR_DEFAULT_DURATION}
                      );
                    }
                    this._planningFacade.fetching = false;
                  })
                  .catch((err) => console.log(err));
              });
          }
        });
      });
  }

  protected _resetSelection(): void {
    this.selectedOperatingSite = null;
    this.selected.clear();
  }

  protected _setMenuPosition(event: MouseEvent): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
  }

  protected _showModal(lines: EventActivityLine[],
                       site: OperatingSite,
                       dates: string[],
                       comment: string | null): Observable<any> {
    const dialogRef = this._dialog.open(EventCreationDialogComponent, {
      data: {
        lines: lines,
        site: site,
        agent: this.agent,
        dates: dates,
        job: this.job,
        activityTypes: this.activityTypes,
        workSchedules: this.workSchedules,
        sitesStatusPlanningByOperatingSite: this.sitesStatusPlanningByOperatingSite,
        sitesStatus: this.sitesStatus,
        id: lines.filter(({id}) => !!id).map(({id}) => id)[0],
        comment: comment,
        version: this._planningFacade.versionSelected
      },
      width: '90%',
      maxWidth: '90%',
    });

    return dialogRef.afterClosed().pipe(take(1));
  }

  protected _getDateSelected(): string[] {
    return [...this.selected]
      .filter((value) => value % 2 == 0)
      .map((value) => this.dates[value / 2]);
  }

}
